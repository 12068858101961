<template>
  <div class="container test-results-new">
    <div class="clearfix">
      <div class="test-results__title">
        {{ $t('profqabilet.title') }}
      </div>
      <div class="test-results__tape">
        <div class="container h-100">
          <div class="test-results__tape-text">
            {{ $t('profqabilet.title-2') }}
          </div>
        </div>
      </div>
      <div style="margin-top: 80px" class="test-results__sub-title">{{ $t('profqabilet.results') }}</div>
      <ul class="sponsor-list mt-5">
        <li>
          <a href="https://fnn.kz" target="_blank" class="sponsor-fond-link">
            <img :src="`${origin}/images/${this.$i18n.locale === 'ru' ? 's-1.png' : 'logo-new-kz.png'}`" width="100"
                 alt="" class="img-fluid">
          </a>
        </li>
        <li>
          <a href="#" target="_blank" class="sponsor-fond-elumiti">
            <img src="/images/EA_logo_final.svg" width="100" alt="" class="img-fluid">
          </a>
        </li>
        <li class="sponsor-fond-circle-logo">
          <img src="/images/MO-bg.png" width="100" alt="" class="img-fluid">
        </li>
      </ul>
    </div>
    <div class="test-results__btns mt-5">
      <router-link to="/testing" style="text-decoration: none!important; color:white!important"
                   class="test-results__other-btn">
        {{ $t('test-results.other-tests') }}
      </router-link>
      <router-link style="text-decoration: none!important; color:white!important" to="/notes"
                   class="test-results__consult-btn">
        {{ $t('test-results.consultations-appointment') }}
      </router-link>

      <div class="test-results__download-block">
        <button @click="downloadReport" class="test-results__download-btn">
          <img src="/images/download-icon.svg" width="16" height="16" class="mr-2">
          {{ $t('test-results.download-file') }}
        </button>
        <div class="test-results__download-title">
          {{ $t('profqabilet.download-file-name') + this.results.surname +'_' + this.results.name + ' .pdf' }}
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueApexCharts,
    VueHtml2pdf
  },
  data() {
    return {
      results: {},
      apiRoot: window.API_ROOT,
      origin: window.location.origin,
    }
  },
  methods: {
    downloadReport(){
      window.open(this.results.link, '_blank')
    },
    getResultNew: async function () {
      this.id = this.$route.query.id
      const userQuizId = this.$route.params.id
      const token = this.$route.params.token

      this.$http.get(window.API_ROOT + `/api/profqabilet/${userQuizId}/${token}`)
          .then((res) => {
            console.log("res new", res.data)
            this.results = res.data
          })
    }
  },
  mounted() {
    this.getResultNew()
  }
}
</script>
<style>
.link-img-elumiti {
  width: 125px;
  height: 125px;
  background-image: url("/images/EA_logo_final.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.link-img-fond {
  width: 100px;
  height: 100px;
  background-image: url("/images/partner-1-1.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.recomendation-wrapper {
  margin-top: 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
  border-radius: 15px;
  padding: 30px 27px;
  font-family: "Roboto Condensed", sans-serif;
}

.recommendation-title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
}

.apexcharts-legend {
  padding-top: 21px;
  margin-top: 1rem !important;
  inset: auto 0px -2px !important;
  position: absolute;
  max-height: 166.667px;
}

.sponsor-list .sponsor-fond-link .sponsor-fond {
  width: 120px;
  height: 87px;
  background-size: contain;
  display: block;
  background-repeat: no-repeat;
}

.sponsor-fond-circle-logo img {
  width: 100px;
  height: 100px;
}

.sponsor-fond-elumiti img {
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsor-list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 0;
}

.sponsor-list li {
  margin-right: 35px;
}

.nav-scroller {
  display: none !important;
}

.test-results__title {
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #343F68;
  padding-top: 60px;
  padding-bottom: 40px;
}

.test-results__tape {
  width: calc(100% - 50px);
  height: 65px;
  border-right: 33px solid transparent;
  border-top: 33px solid #1B83B1;
  border-left: 33px solid #1B83B1;
  border-bottom: 33px solid #1B83B1;
  position: absolute;
  left: 0;
}

.test-results__tape-text {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
  padding-left: 24px;
}

.test-results__date-block {
  margin-top: 113px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.test-results__date {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 14px 16px;
  align-items: center;
  font-size: 20px;
  line-height: 23px;
  color: #303030;
}

.test-results__date-block .test-results__date:first-child {
  margin-right: 20px;
}

.test-results__sub-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  padding-bottom: 40px;
}

.test-results__section {
  /*margin-top: 80px;*/
}

.test-results__section-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.test-results__card {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 30px 27px;
  width: 700px !important;
  height: fit-content;
  font-family: "Roboto Condensed", sans-serif;
  margin-left: 20px;
}

.test-results__card-title {
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;
  color: #1B83B1;
  text-align: center;
  padding-bottom: 16px;
}

.test-results__card-title--yellow {
  color: #F3A93C;
}

.test-results__card-title--recomend {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #1B83B1;
}

.recomendation-bg {

  padding: 5px 10px 10px 30px;
  border-radius: 15px;
  margin-top: 1rem;
  background-color: #e0f4fd;
}

.recomendation-wh {
  padding: 5px 10px 10px 30px;
  border-radius: 15px;
  margin-top: 1rem;
  background-color: white;
}

.test-results__card-title--recomend-min {
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #1B83B1;
}

.test-results__card-sub-title {
  color: #F3A93C;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 28px;
  text-align: center;
}

.test-results__card-text {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}

.test-results__btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 84px;
  align-items: center;
}

.test-results__other-btn,
.test-results__consult-btn {
  border: none;
  border-radius: 5px;
  padding: 10px 24px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  height: fit-content;
  outline: none;
}

.test-results__other-btn {
  background: #1B83B1;
}

.test-results__consult-btn {
  background: #18A0FB;
  margin-left: 24px;
  margin-right: 24px;
}

.test-results__download-block {
  display: flex;
  flex-direction: row;
  padding: 4px;
  border-radius: 4px;
  border: 1px dashed #1B83B1;
  align-items: center;
}

.test-results__download-btn {
  color: #0070D2;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #DDDBDA;
  box-sizing: border-box;
  border-radius: 4px;
  background: #FFFFFF;
  padding: 5px 23px;
  outline: none;
}

.test-results__download-title {
  color: #737373;
  font-size: 13px;
  line-height: 20px;
  margin-left: 22px;
  margin-right: 12px;
}

.test-results__img {
  height: 425px;
  width: 425px;
  display: flex;
  justify-content: center;
}

.apexcharts-radialbar .apexcharts-datalabels-group {
  display: none;
}

@media screen and (max-width: 1200px) {
  .test-results__card {
    width: 453px;
  }
}

@media screen and (max-width: 991px) {
  .test-results__tape-text {
    padding-left: 0;
  }

  .test-results__section-row {
    flex-direction: column;
  }

  .test-results__card {
    margin-left: 0;
    margin-top: 24px;
  }

  .test-results__date-block {
    flex-direction: column;
    align-items: center;
  }

  .test-results__date-block .test-results__date:first-child {
    margin-right: 0;
    margin-bottom: 12px;
    width: fit-content;
  }

  .test-results__btns {
    flex-direction: column;
    align-items: center;
  }

  .test-results__consult-btn {
    margin: 20px 0;
  }

  .test-results__card {
    width: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .test-results__tape-text {
    font-size: 16px;
  }

  .test-results__img {
    width: 100%;
  }

  .test-results__img-recomend {
    width: 312px;
    height: 217px;
  }

  .test-results__img-result {
    width: 312px;
    height: 312px;
  }

  .test-results-new .test-results__card {
    max-width: 100%;
    padding-top: 0;
  }

  .test-results-new .recomendation-wh {
    padding: 0;
  }
}

@media screen and (max-width: 459px) {
  .sponsor-list {
    padding-left: 0 !important;
    flex-direction: column;
    align-items: none;

  }

  .sponsor-list li {
    margin: 1rem auto 0;
  }

  .test-results__btns {
    padding-bottom: 160px;
  }
}

.pdf-content {
  display: none;
}

.pdf-page {
  page-break-after: always;
  page-break-inside: avoid;
}
</style>
